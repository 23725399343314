import Cross from '../../assets/cross';
import { DownloadOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    Button, Drawer, message, Radio,
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import * as React from 'react';
// import withStyles from 'react-jss';
import Helper from '../../library/Helper';
import { createBulk, createBulkInternational, downloadSample } from '../../network/consignments.api';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { bulkConsignmentStyles } from './bulk-consignments.styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { getCustomerCode, getEmployeeStorage, isSuperUserEmployee } from 'auth/local-storage';
import { Master } from 'types/master-data-types';

interface BulkConsignmentProps extends StylesProps<ReturnType<typeof bulkConsignmentStyles>> {
    onClose: () => void;
    config: {
        name: string;
        uploadName: string;
    };
    allowInternational: any;
    showServiceTypeOption?: boolean;
    itemArrayInDomestic: any;
    uiTheme: ThemeType;
    source: string;
    master: Master;
}

const BulkConsignment = (props: BulkConsignmentProps) => {
    const {
        classes,
        onClose,
        config,
        allowInternational,
        showServiceTypeOption,
        itemArrayInDomestic,
        uiTheme,
        source,
        master,
    } = props;

    const { t } = useTranslation();

    const [consignmentArray, setConsignments] = React.useState<any>([]);
    const [piecesArray, setPieces] = React.useState<any>([]);
    const [itemsArray, setItems] = React.useState<any>([]);
    const [creating, setCreating] = React.useState<boolean>(false);
    const customerProperties = master?.properties;
    const [consignmentCategory, setConsignmentCategory] = React.useState(
        customerProperties.domestic_consignment ? 'Domestic' : 'International',
    );
    const [shipmentType, setShipmentType] = React.useState('Commercial');
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;

    const handleCreate = async () => {
        setCreating(true);
        let response;
        if (consignmentCategory === 'International') {
            response = await createBulkInternational({
                consignmentArray,
                piecesArray,
                itemsArray,
                isBookedUsingV2: true,
                isPluginOrder: source === 'plugin' ? true : undefined,
            });
        } else {
            response = await createBulk({
                consignmentArray,
                piecesArray,
                itemsArray: itemArrayInDomestic ? itemsArray : undefined,
                isBookedUsingV2: true,
                isPluginOrder: source === 'plugin' ? true : undefined,
            });
        }
        if (response.isSuccess) {
            message.success(t('excel_upload_success'));
            onClose();
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (consignmentArray.length) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                loading={creating}
                className={btnClass}
                disabled={!consignmentArray.length}
                style={{
                    border: `1px solid ${!consignmentArray?.length ? '#EDEDED'
                        : uiTheme.primaryColor}`,
                }}
                onClick={handleCreate}
            >
                {t('submit')}
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {config.name}
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const mapping: Record<string, string> = {
        Unique_Id: 'uniqueId',
        'Client Code': 'clientCode',
        'Consignment Number': 'referenceNumber',
        'Service Type': 'serviceType',
        'Courier Type': 'courierType',
        'Customer Reference Number': 'customerReferenceNumber',
        'Declared Price (non-document)': 'declaredPrice',
        'Risk Surcharge (YES/NO) (non-document)': 'isRiskSurchargeApplicable',
        'Weight(KG) (non-document)': 'weight',
        'Length(cm) (non-document)': 'dimensionslength',
        'Width(cm) (non-document)': 'dimensionswidth',
        'Height(cm) (non-document)': 'dimensionsheight',
        'Origin Name': 'srcAddressname',
        'Consignor Name': consignmentCategory === 'International' ? 'consignorAddressname' : 'srcAddressname',
        'Origin Address Line 1': 'srcAddressaddressLine1',
        'Origin Address Line 2': 'srcAddressaddressLine2',
        'Origin City': 'srcAddresscityName',
        'Origin State': 'srcAddressstateName',
        'Origin Pincode': 'srcAddresspincode',
        'Origin Phone': 'srcAddressphone',
        'Consignor Phone': consignmentCategory === 'International' ? 'consignorAddressphone' : 'srcAddressphone',
        'Consignor Address Line 1': 'consignorAddressaddressLine1',
        'Consignor Address Line 2': 'consignorAddressaddressLine2',
        'Consignor City': 'consignorAddresscityName',
        'Consignor State': 'consignorAddressstateName',
        'Consignor Country': 'consignorAddresscountryName',
        'Consignor Email': 'consignorAddressemail',
        'Consignor Pincode': 'consignorAddresspincode',
        'Destination Pincode': 'dstAddresspincode',
        'Consignee Name': 'dstAddressname',
        'Destination Name': 'dstAddressname',
        'Consignee Phone': 'dstAddressphone',
        'Destination Phone': 'dstAddressphone',
        'Destination Address Line 1': 'dstAddressaddressLine1',
        'Destination Address Line 2': 'dstAddressaddressLine2',
        'Destination City': 'dstAddresscityName',
        'Destination State': 'dstAddressstateName',
        'Product Code': 'productCode',
        'Eway Bill': 'ewayBill',
        'Content Type': 'commodityId',
        'Consignment Type': 'consignmentType',
        'In Favor Of': 'codFavorOf',
        'Cod Mode': 'codCollectionMode',
        'Cod Amount': 'codAmount',
        Description: 'description',
        'Origin Country': 'srcAddresscountryName',
        'Destination Country': 'dstAddresscountryName',
        'Destination Latitude': 'dstAddresslatitude',
        'Destination Longitude': 'dstAddresslongitude',
        'Consignee Id': 'consigneeId',
        'Rescheduled Starttime': 'rescheduledStarttime',
        'Rescheduled Date': 'rescheduledDate',
        'Rescheduled Endtime': 'rescheduledEndtime',
        'Origin Company Name': 'srcCompanyName',
        'Consignee EmailId': 'consigneeEmail',
        'Dst Company Name': 'dstCompanyName',
        Notes: 'notes',
        'Number of packages': 'noOfPackages',
        'Inco Terms': 'incoTerms',
        'Shipment Purpose': 'shipmentPurpose',
        'Manifest Number': 'manifestNumber',
        Currency: 'currency',
        'Declared Currency': 'declaredCurrency',
        'Movement Type': 'movementType',
        'Pickup Start Time (HH:MM)': 'pickupTimeSlotStart',
        'Pickup Service Time (Mins)': 'pickupServiceTime',
        'Pickup End Time (HH:MM)': 'pickupTimeSlotEnd',
        'Delivery Start Time (HH:MM)': 'deliveryTimeSlotStart',
        'Delivery End Time (HH:MM)': 'deliveryTimeSlotEnd',
        'Delivery Service Time (Mins)': 'deliveryServiceTime',
        'Pieces Detail': 'piecesDetail',
        'Number of Pieces (non-document)': 'numberOfPieces',
        'Invoice Number': 'invoiceNumber',
        'Destination Address Line 3': 'dstAddressaddressLine3',
        'Destination Address Line 4': 'dstAddressaddressLine4',
        'CPR Number': 'cprNumber',
        'SIM Number': 'simNumber',
        'Device 1': 'device1',
        'Device 2': 'device2',
        'Device 3': 'device3',
        'IMEI 1': 'imei1',
        'IMEI 2': 'imei2',
        'IMEI 3': 'imei3',
        'ALT DEL 1 Name': 'alternateDelAddress1name',
        'ALT DEL 1 Address Line 1': 'alternateDelAddress1addressLine1',
        'ALT DEL 1 Address Line 2': 'alternateDelAddress1addressLine2',
        'ALT DEL 1 Address Line 3': 'alternateDelAddress1addressLine3',
        'ALT DEL 1 Address Line 4': 'alternateDelAddress1addressLine4',
        'ALT DEL 1 Pincode': 'alternateDelAddress1pincode',
        'ALT DEL 1 Phone': 'alternateDelAddress1phone',
        'ALT DEL 1 Alternate Phone': 'alternateDelAddress1alternatePhone',
        'ALT DEL 1 City': 'alternateDelAddress1cityName',
        'ALT DEL 1 State': 'alternateDelAddress1stateName',
        'ALT DEL 1 Country': 'alternateDelAddress1countryName',
        'ALT DEL 2 Address Line 1': 'alternateDelAddress2addressLine1',
        'ALT DEL 2 Address Line 2': 'alternateDelAddress2addressLine2',
        'ALT DEL 2 Address Line 3': 'alternateDelAddress2addressLine3',
        'ALT DEL 2 Address Line 4': 'alternateDelAddress2addressLine4',
        'ALT DEL 2 Name': 'alternateDelAddress2name',
        'ALT DEL 2 Pincode': 'alternateDelAddress2pincode',
        'ALT DEL 2 Phone': 'alternateDelAddress2phone',
        'ALT DEL 2 Alternate Phone': 'alternateDelAddress2alternatePhone',
        'ALT DEL 2 City': 'alternateDelAddress2cityName',
        'ALT DEL 2 State': 'alternateDelAddress2stateName',
        'ALT DEL 2 Country': 'alternateDelAddress2countryName',
        'REDIRECT Name': 'redirectionAddressname',
        'REDIRECT Address Line 1': 'redirectionAddressaddressLine1',
        'REDIRECT Address Line 2': 'redirectionAddressaddressLine2',
        'REDIRECT Address Line 3': 'redirectionAddressaddressLine4',
        'REDIRECT Address Line 4': 'redirectionAddressaddressLine4',
        'REDIRECT Pincode': 'redirectionAddresspincode',
        'REDIRECT Phone': 'redirectionAddressphone',
        'REDIRECT Alternate Phone': 'redirectionAddressphone',
        'REDIRECT City': 'redirectionAddresscityName',
        'REDIRECT State': 'redirectionAddressstateName',
        'REDIRECT Country': 'redirectionAddresscountryName',
        'Consignor Alternate Phone': 'srcAddressalternatePhone',
        'Dimension Unit': 'dimensionUnit',
        'Weight Unit': 'weightUnit',
        'Invoice Date': 'invoiceDate',
        'Export Invoice Date': 'exportInvoiceDate',
        'Consignor Kyc Doc Type': 'consignorKycDocType',
        'Consignor Kyc Doc Number': 'consignorKycDocNumber',
        'Consignor Kyc Front Image': 'consignorKycFrontImage',
        'Consignor Kyc Back Image': 'consignorKycBackImage',
        'Invoice Type': 'invoiceType',
        'Is Battery': 'isBattery',
        'Consignor Company Name': 'consignorCompanyName',
        'Consignee Company Name': 'consigneeCompanyName',
        'Sender Type': 'originType',
        'consignor IEC Number': 'consignorIECNumber',
        'consignor PAN Id': 'consignorPanId',
        'consignor Tax Id': 'consignorTaxId',
        'Destination Type': 'destinationType',
        'NEFI flag': 'nefiFlag',
        'Customer Seller Code': 'custSellerCode',
        'Consignor GSTIN Number': 'consignorGSTINNumber',
        'Cess Value': 'cessValue',
        'Sender CPC Code': 'cpcCode',
        'Sender Bank Account No.': 'senderBankAccountNumber',
        'Sender Bank AD Code': 'senderBankADCode',
        'Sender Bank Name': 'senderBankName',
        'Sender Bank IFSC Code': 'senderBankIFSCCode',
        'Billing Address Pincode': 'billAddresspincode',
        'Biller Name': 'billAddressname',
        'Biller Phone': 'billAddressphone',
        'Billing Address Line 1': 'billAddressaddressLine1',
        'Billing Address Line 2': 'billAddressaddressLine2',
        'Billing Address City': 'billAddresscityName',
        'Billing Address State': 'billAddressstateName',
        'Billing Address Country': 'billAddresscountryName',
        'Billing Address Email': 'billAddressemail',
        'Biller Alternate Phone': 'billAddressalternatePhone',
        'Delivery Instructions': 'deliveryInstructions',
        'Against Bond Lut': 'againstBondLut',
        'ECom Shipment': 'ecomShipment',
        MEIS: 'meis',
        'IORI Number': 'ioriNumber',
        'EORI Number': 'eoriNumber',
        'Freight Cost': 'freightCost',
        'Freight Cost Currency': 'freightCostCurrency',
        'FOB Value': 'fobValue',
        'FOB Value Currency': 'fobValueCurrency',
        'Insurance Value': 'insuranceValue',
        'Insurance Value Currency': 'insuranceValueCurrency',
        'Total GST Paid Amount': 'totalGSTPaidAmount',
        'Total GST Paid Currency': 'totalGSTPaidCurrency',
        'EOR Details': 'eorDetails',
        'IOR Details': 'iorDetails',
        'Receiver VAT Number': 'receiverVATNumber',
        'Pickup Hub Code': 'pickupHubCode',
        'Shipment Terms': 'deliveryTerms',
        'Type Of Delivery': 'typeOfDelivery',
        'Retail Transaction': 'isRetailTransaction',
        'Cash On Pickup Amount': 'copAmount',
        'Origin Address Email': 'srcAddressemail',
        'Destination Address Email': 'dstAddressemail',
        'Origin W3W Code': 'srcAddressw3wCode',
        'Destination W3W Code': 'dstAddressw3wCode',
        'EWB Date': 'ewbDate',
        'EWB Number': 'ewbNumber',
        'Origin Address Hub Code': 'srcAddressaddressHubCode',
        'Destination Address Hub Code': 'dstAddressaddressHubCode',
        'Return Address Hub Code': 'retAddressaddressHubCode',
        'Risk Surcharge Type': 'riskSurchargeType',
        'Consignee Alternate Phone': 'dstAddressalternatePhone',
        'SRF Number': 'srfNumber',
        'Pickup Date (MM/DD/YYYY)': 'pickupDate',
        'Origin Address Code': 'srcAddressaddressCode',
        'Destination Address Code': 'dstAddressaddressCode',
        'Return Name': 'retAddressname',
        'Return Phone': 'retAddressphone',
        'Return Address Line 1': 'retAddressaddressLine1',
        'Return Address Line 2': 'retAddressaddressLine2',
        'Return City': 'retAddresscityName',
        'Return State': 'retAddressstateName',
        'Return Country': 'retAddresscountryName',
        'Return Pincode': 'retAddresspincode',
        'RTO Name': 'retAddressname',
        'RTO Phone': 'retAddressphone',
        'RTO Address Line 1': 'retAddressaddressLine1',
        'RTO Address Line 2': 'retAddressaddressLine2',
        'RTO City': 'retAddresscityName',
        'RTO State': 'retAddressstateName',
        'RTO Country': 'retAddresscountryName',
        'RTO Pincode': 'retAddresspincode',
        'Line Meters': 'lineMeters',
        'Sender Partner Id': 'senderPartnerId',
        'Receiver Partner Id': 'receiverPartnerId',
        'High Value Id Number': 'highValueDetailsidNum',
        'High Value Name': 'highValueDetailsname',
        'High Value Id Expiration': 'highValueDetailsidExpiry',
        'High Value Id Place Of Issue': 'highValueDetailsissuePlace',
        'High Value Nationality': 'highValueDetailsnationality',
        'High Value Mobile No': 'highValueDetailsphone',
        'High Value Amount': 'highValueDetailsamount',
        'High Value Billed To Merchant': 'highValueDetailsbilledToMerchant',
        'HS Code': 'highValueDetailshsCode',
        'HSN Code': 'hsnCode',
        'Customer Civil Id': 'customerCivilId',
        'Receiver Civil Id': 'receiverCivilId',
        'Fragile content': 'isFragile',
        'Commodity Name': 'commodityName',
        'Source address alternate phone': 'srcAddressalternatePhone',
        'Destination address alternate phone': 'dstAddressalternatePhone',
        'IMEI-1': 'imei1',
        'IMEI-2': 'imei2',
        'IMEI-3': 'imei3',
        'Sim Number': 'simNumber',
        Quantity: 'quantity',
        'Service Item': 'serviceItem',
        'Service Item Dimension Unit': 'serviceItemDimensionUnit',
        'Item Quantity': 'itemQuantity',
        'Item Unit': 'itemUnit',
        'Is International': 'isInternational',
        'GSTIN Shipper': 'senderGstin',
        'GSTIN Receiver': 'receiverGstin',
        'Invoice Link': 'invoiceLink',
        'Movement Classification': 'movementClassification',
        'Pudo Store': 'kiranaHubCode',
        'National Destination Code': 'dstAddressnationalDestinationCode',
        'Package Type': 'packagingType',
        'PUDO Cod Amount': 'pudoCodAmount',
        'AK Number': 'akNumber',
        'Destination Location Id': 'dstAddresslocationId',
        'Destination Location Type': 'dstAddresslocationType',
        'Origin Location Id': 'srcAddresslocationId',
        'Origin Location Type': 'srcAddresslocationType',
        'Collect Import Vat': 'collect_import_vat',
        'ASN Number': 'asnNumber',
        'Origin Location Code': 'originLocationCode',
        'Num Packages': 'num_packages',
        'Mode of pickup': 'modeOfPickup',
        'Prepaid Amount': 'prepaidAmount',
        'Group Code': 'groupCode',
        'Customs Amount': 'customsAmount',
        'Customs Currency': 'customsCurrency',
        'Movement Reference Number': 'movementReferenceNumber',
        'Is Customs Declarable': 'isCustomsDeclarable',
        'Consignee Code': 'consigneeCode',
        'Invoice Amount': 'invoiceAmount',
        Purpose: 'purpose',
        'Consignor Code': 'sellerCode',
        'Pay Basis LTL': 'payBasis',
        'Fulfillment Id': 'salesOrderNumber',
    };

    const pieceDetailHeaderMapping: Record<string, string> = {
        'Customer Reference Number': 'customerReferenceNumber',
        Unique_Id: 'uniqueId',
        Description: 'description',
        Quantity: 'quantity',
        Length: 'length',
        Width: 'width',
        Height: 'height',
        Weight: 'weight',
        'Dimension Unit': 'dimensionUnit',
        'Weight Unit': 'weightUnit',
        Volume: 'volume',
        'Volume Unit': 'volumeUnit',
        'Model Code': 'modelCode',
        'Item Category': 'itemCategory',
        'Item Size': 'itemSize',
        'Piece Reference Number': 'pieceReferenceNumber',
        'Reference Number': 'pieceReferenceNumber',
        'Piece Number': 'pieceNumber',
        'Number of Items': 'numberOfItems',
        'Declared Value': 'declaredValue',
        'Volumetric Weight': 'volumetricWeight',
        'Piece Unique ID': 'pieceUniqueId',
        'Item Unit': 'itemUnit',
        'Piece Product Code': 'pieceProductCode',
        'Cod Amount': 'codAmount',
        'Net Weight': 'netWeight',
        'HSN Code': 'hsnCode',
        'Fulfillment Id': 'salesOrderNumber',
    };

    const itemDetailHeaderMapping: Record<string, string> = {
        'Customer Reference Number': 'customerReferenceNumber',
        'Piece Number': 'pieceNumber',
        Quantity: 'numberOfItems',
        'Item Rate': 'itemValue',
        'Item Description': 'itemDescription',
        'SKU Number': 'skuNumber',
        'HSN Code': 'hsnCode',
        'Item Total Value': 'dutyValue',
        'Product Dead Weight': 'productDeadWeight',
        'Product Type': 'productType',
        'Product Url': 'productUrl',
        'Origin Of Goods': 'originOfGoods',
        'Gst Value': 'gstValue',
        'Gst Percentage': 'gstPercentage',
        'Cess Value': 'cessValue',
        'ATE Number': 'ateNumber',
        Composition: 'composition',
        'Rodtep Scheme': 'rodtepScheme',
        'Product Height': 'productHeight',
        'Product Length': 'productLength',
        'Product Width': 'productWidth',
        'Cod Amount': 'codAmount',
        'Piece Unique ID': 'pieceUniqueId',
        'Item Number': 'itemNumber',
        'HS Code': 'hsCode',
        'Manufacturing Country': 'manufacturingCountry',
        'Cost Per Piece': 'costPerPiece',
    };

    const DateKeys: string[] = [
        'exportInvoiceDate',
        'invoiceDate',
        'ewbDate',
    ];

    const getConsignmentArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const isSuperUser = isSuperUserEmployee();
        const employee = getEmployeeStorage();
        const customerCode = getCustomerCode();
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = mapping[keyUpdate];
                if (DateKeys.includes(keyUpdate)) {
                    returnObj[keyUpdate] = moment(item[key]).add(1, 'h').format('YYYY-MM-DD');
                } else {
                    returnObj[keyUpdate] = item[key];
                }
            });
            returnObj.createdBy = isSuperUser ? `${employee.employeeCode} (Employee)` : `${customerCode} (Customer)`;

            return returnObj;
        });
        return newData;
    };

    const getPiecesArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = pieceDetailHeaderMapping[keyUpdate];
                if (keyUpdate) {
                    returnObj[keyUpdate] = item[key];
                }
            });
            return returnObj;
        });
        return newData.filter((obj: any) => (Object.keys(obj).length) > 0);
    };

    const getItemsArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const itemDetailsMapping = consignmentCategory === 'Domestic'
            ? {
                ...itemDetailHeaderMapping,
                Quantity: 'quantity',
            }
            : itemDetailHeaderMapping;
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = itemDetailsMapping[keyUpdate];
                if (keyUpdate) {
                    returnObj[keyUpdate] = item[key];
                }
            });
            return returnObj;
        });
        return newData.filter((obj: any) => (Object.keys(obj).length) > 0);
    };

    const transformData = (data: any[]) => {
        const newData: any = {};
        newData.consignmentArray = getConsignmentArray(data[0]);
        if (data.length > 1) {
            newData.piecesArray = getPiecesArray(data[1]);
            if (data.length > 2) {
                newData.itemsArray = getItemsArray(data[2]);
            }
        }
        return newData;
    };

    const handleParsedData = async (data: any[]) => {
        if (!data || !data.length) return;
        const slicedData = transformData(data);
        setConsignments(slicedData.consignmentArray);
        setPieces(slicedData.piecesArray);
        setItems(slicedData.itemsArray);
    };

    const onFileRecieved = async (file: any) => {
        const consignmentArr: any[] = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        handleParsedData(consignmentArr);
    };

    const handleUploadChange = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    multiple={false}
                    accept=".xlsx, .xls, .csv"
                    name="file"
                    disabled={consignmentArray.length}
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                >
                    <p className={classes.uploadText}>
                        <UploadOutlined className={classes.closeIcon} />
                        {t('bulk_consignment_upload_text')}
                    </p>
                    <p className={classes.uploadHint}>
                        {t('drag_and_drop_text')}
                        {t('or')}
                        <Button type="link" color={uiTheme.primaryColor}>
                            {t('Browse')}
                        </Button>
                        {t('to choose file')}
                    </p>
                </Dragger>
            </div>
        );
    };

    const handleDownload = async () => {
        let fileBuffer;

        if (consignmentCategory === 'International') {
            fileBuffer = await downloadSample({
                isInternational: true,
                isCommercialShipment: shipmentType === 'Commercial',
            });
        } else {
            fileBuffer = await downloadSample({ isInternational: false });
        }

        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'sample-consignment-upload.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
    };

    const renderDownload = () => {
        return (
            <Button
                type="link"
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                <DownloadOutlined />
                {t('download_sample_file')}
            </Button>
        );
    };

    const renderConsignmentCategory = () => {
        let categoryOptions = [];
        if (customerProperties && enable_customer_config) {
            if (customerProperties.domestic_consignment) {
                categoryOptions.push(t('domestic'));
            }
            if (customerProperties.international_consignment) {
                categoryOptions.push(t('international'));
            }
        } else {
            categoryOptions = [t('domestic'), t('international')];
        }

        const shipmentTypeOptions = [t('commercial'), t('non_commercial')];
        return (
            <>
                <div className={classes.consignmentCategory}>
                    <div className={classes.flexColumn}>
                        {t('consignment_category')}
                        :
                    </div>
                    <div>
                        <Radio.Group
                            options={
                                categoryOptions
                            }
                            value={consignmentCategory}
                            onChange={(e) => setConsignmentCategory(e.target.value)}
                        />
                    </div>
                </div>
                {
                    (showServiceTypeOption && consignmentCategory === 'International'
                        ? (
                            <div className={classes.consignmentCategory}>
                                <div className={classes.flexColumn}>
                                    {t('shipment_type')}
                                    :
                                </div>
                                <div>
                                    <Radio.Group
                                        options={
                                            shipmentTypeOptions
                                        }
                                        value={shipmentType}
                                        onChange={(e) => setShipmentType(e.target.value)}
                                    />
                                </div>
                            </div>
                        )
                        : null
                    )
                }
            </>
        );
    };

    const renderDetails = () => {
        return (
            <div>
                <div className={classes.details}>
                    <p className={classes.note}>
                        <ExclamationCircleOutlined style={{ fontSize: '16px' }} />
                        &nbsp;
                        Please Note
                    </p>
                    <div>
                        <ul>
                            <li>
                                We have introduced a new feature called&nbsp;
                                <strong>“Consignor Admin”</strong>
                                &nbsp;
                                which allows customers
                                to create and manage KYC profiles for themselves and their senders. These profiles
                                can be used during the booking process, with consignor details automatically retrieved
                                from the saved profile, eliminating the need to enter them for every booking.
                            </li>
                            <li>
                                You shall pass the consignor code of the profile in the field&nbsp;
                                <strong>“Consignor Code”</strong>
                                &nbsp;
                                in the CSV file during upload.
                            </li>
                            <li>
                                The system has been enhanced to capture two distinct addresses for a shipment: the
                                Consignor address and the Pickup address. The shipment will be collected from the
                                Pickup address, while the Consignor address is necessary for shipment clearance.
                            </li>
                            <li>
                                Consignor address is captured using the fields&nbsp;
                                <strong>
                                    Consignor address line 1, Consignor address line 2, Consignor city,
                                    Consignor state, Consignor country.
                                </strong>
                            </li>
                            <li>
                                Pickup address is captured using the fields&nbsp;
                                <strong>
                                    Origin address line 1, Origin address line 2, Origin city,
                                    Origin state, and Origin country.
                                </strong>
                            </li>
                            <li>
                                Also, an&nbsp;
                                <strong>“Insurance Value”</strong>
                                &nbsp;
                                field has been added to capture the insurance value for shipments with CIF incoterms.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {allowInternational ? renderConsignmentCategory() : null}
            {renderUpload()}
            {source === 'plugin' ? null : renderDownload()}
            {consignmentCategory === 'International' && renderDetails()}
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: bulkConsignmentStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default (GenericHoc(hocConfig)(BulkConsignment));
