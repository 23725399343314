import * as React from 'react';
import moment from 'moment';
import { useGenericState } from '../../hooks/use-generic-state';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { singleConsignmentStyles } from './single-consignment.styles';
import StepOne from './single-consignment-steps/step-one';
import StepFour from './single-consignment-steps/step-four';
import StepTwo from './single-consignment-steps/step-two';
import StepThree from './single-consignment-steps/step-three';
import withStyles from 'react-jss';
import { UpdatePluginOrder, createConsignment } from '../../network/consignments.api';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import Cross from '../../assets/cross';
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import {
    ButtonNames,
    initialState,
    getRequiredKeys,
    TabNames,
    formFields,
    consignorFormFields,
    editInitialState,
    CreateActions,
    ConsignorAddressType,
} from './create-modal.constants';
import {
    ButtonAction,
    CurrentPage,
    CurrentPageNumber,
    NewConsignmentState,
} from './create-consignment.types';
import {
    Button,
    Checkbox,
    Drawer,
    Form,
    message,
    Modal,
} from 'antd';
import Helper from 'library/Helper';
import ConsignorDetailsInteranational from './single-consignment-steps/consignor-details-Interanational';
import { useTranslation } from 'react-i18next';
import { last } from 'lodash';
import { loadPluginOrders } from 'actions/plugin-orders-actions';
import { UploadFile } from 'antd/lib/upload/interface';
import cnHelper from './consignment-helper';
import { getEmployeeStorage, isSuperUserEmployee } from 'auth/local-storage';
import SellerDetailsInternational from './single-consignment-steps/seller-details-International';
import { consignmentCategoryTypes } from 'components/consignments/consignments.constants';

interface SingleConsignmentProps extends StylesProps<ReturnType<typeof singleConsignmentStyles>> {
    config: any;
    onClose: () => void;
    setSuccess: (data: any) => void;
    loadconsignments: () => void;
    isRTL: any;
    uiTheme: ThemeType;
    editData?: any;
    source?: string;
    actionType?: any;
}

const SingleConsignment = (props: SingleConsignmentProps) => {
    const {
        config,
        classes,
        onClose,
        setSuccess,
        loadconsignments,
        isRTL,
        uiTheme,
        editData,
        source,
        actionType,
    } = props;
    const { t, i18n } = useTranslation();
    const phoneRegex = config?.parts_to_show?.remove_regex ? null : config?.config?.phone_regex;
    const countryWisePhoneRegex = config?.config?.country_wise_phone_regex || [];
    const isReverse = config.Customer?.is_reverse;
    const customerPortalConfig = config?.config?.customer_portal_config;
    const allowDefaultSourceAddress = !customerPortalConfig?.disable_default_address;
    const consignmentConfig = config?.config?.customer_portal_consignment_config;
    const mandatoryPieceDetails = customerPortalConfig?.mandatory_piece_details || {};
    const mandatoryFieldNonDocIntl = customerPortalConfig?.mandatory_field_non_document_international || {};
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    const fieldsToShowInternational = customerPortalConfig?.fields_to_show_international || {};
    const tabNamesConfig = customerPortalConfig?.single_consignment_tab_names || {};
    const partsToShowSingleConsignment = consignmentConfig?.parts_to_show_single_consignment;
    const customerProperties = config?.properties;
    const isLTLCustomer = config?.Customer?.customer_category === 'ltl';
    const isFTL = actionType === CreateActions.FTLHyperlocal;
    const [form] = Form.useForm();
    // using this to check if international consignor address coming from address book or profile
    const [consignorAddressType, setConsignorAddressType] = React.useState<ConsignorAddressType>(
        ConsignorAddressType.profile,
    );
    const [sameChecked, setSameChecked] = React.useState<
    Record<'billToDetails'|'srcAddressConsignor'|'retAddressConsignor'|'retAddressSrc', boolean>>({
        billToDetails: false,
        srcAddressConsignor: true,
        retAddressConsignor: true,
        retAddressSrc: false,
    });

    const profileMandateComm = config?.config?.profile_mandate_intl_comm_cp;
    const profileMandateNonComm = config?.config?.profile_mandate_intl_noncomm_cp;

    const [creating, setCreating] = React.useState<boolean>(false);
    const [closeConfirm, setCloseConfirm] = React.useState<boolean>(false);
    const [consentForm, setConsentForm] = React.useState<boolean>(false);
    const [state, setState] = useGenericState<NewConsignmentState>(editData ? editInitialState : initialState);
    const [formData, setFormData] = useGenericState<any>({
        consignmentType: isReverse ? 'reverse' : 'forward',
        consignmentCategory: customerProperties?.domestic_consignment ? 'domestic' : 'international',
        courierType: 'non-document',
        typeOfDelivery: 'HOME_DELIVERY',
        adressOption: 'saved',
        weightUnit: 'kg',
        currency: config?.config?.customer_portal_consignment_config?.default_currency_for_cn_creation || 'INR',
        unit: 'cm',
        serviceType: config?.config?.customer_portal_consignment_config?.default_service_type_for_cn_creation,
        pickupSchedule: moment(),
        ewayBillType: 'ewaybill_number',
        // this is required to start editing data
        ...cnHelper.covertDetailsToCustomerPortal(editData),
    });
    const TitleTabNames = {
        ...TabNames,
        ...tabNamesConfig,
        FIFTH: formData?.consignmentCategory === 'international' ? 'invoice_details' : 'payment_details',
    };

    const [allowCreate, setAllowCreate] = React.useState(true);
    const [agreeToTerms, setAgreeToTerms] = React.useState(false);

    let showPayment = config?.config?.customer_portal_consignment_config?.show_custom_fields
        ? config?.config?.customer_portal_consignment_config?.single_consignment_creation_fields.some((item:any) => {
            return item.id === 'cod';
        })
        : true;
    showPayment = showPayment && !isFTL;

    const showEwayBill = config?.config?.customer_portal_consignment_config?.show_eway_bill_details || false;
    const pagesToShow = Object.keys(CurrentPage);
    if (!showPayment) {
        pagesToShow.pop();
    }

    const handleKycDoc = () => {
        const frontList: UploadFile[] = formData?.consignorKycFrontImage
            && (formData?.consignorKycFrontImage?.file?.status === undefined) ? [
                {
                    uid: '1',
                    name: `${formData?.consignorKycDocType}_front`,
                    status: 'done',
                    url: formData?.consignorKycFrontImage,
                },
            ] : [];
        const backList: UploadFile[] = formData?.consignorKycBackImage
            && formData?.consignorKycBackImage?.file?.status === undefined ? [
                {
                    uid: '1',
                    name: `${formData?.consignorKycDocType}_back`,
                    status: 'done',
                    url: formData?.consignorKycBackImage,
                },
            ] : [];
        form.setFieldsValue({
            consignorKycFrontImage: formData?.consignorKycFrontImage,
            consignorKycBackImage: formData?.consignorKycBackImage,
        });
        setFormData({
            ...formData,
            consignorKycFrontImage: formData?.consignorKycFrontImage,
            consignorKycBackImage: formData?.consignorKycBackImage,

            ConsignorKycDocList: frontList,
            ConsignorKycDocBackList: backList,
        });
    };

    React.useEffect(() => {
        handleKycDoc();
        if (editData?.softdata_body?.stepToOpen) {
            switch (editData?.softdata_body?.stepToOpen) {
                case 5: setState({ currentPage: CurrentPage.FIFTH }); break;
                case 2: setState({ currentPage: CurrentPage.SECOND }); break;
                case 3: setState({ currentPage: CurrentPage.THIRD }); break;
                case 4: setState({ currentPage: CurrentPage.FOUR }); break;
                case 1:
                default: setState({ currentPage: CurrentPage.FIRST });
            }
        }
    }, []);

    React.useEffect(() => {
        setState({
            visited: {
                ...state.visited,
                [state.currentPage]: true,
            },
        });
    }, [state.currentPage]);
    const ewayBillKeys = [
        formFields.TransactionType.key,
        formFields.InwardTransactionSubTypes.key,
        formFields.ApproxDistance.key,
        formFields.DocumentTypes.key,
        formFields.DocumentNumber.key,
        formFields.DocumentDate.key,
        formFields.HsnValue.key,
        formFields.ProductCGST.key,
        formFields.ProductSGST.key,
        formFields.ProductIGST.key,
        formFields.ProductCESS.key,
        formFields.SenderGSTIN.key,
        formFields.RecieverGSTIN.key,
    ];
    const ewayNumberKeys = [
        formFields.EwayBillNumber.key,
    ];


    const checkError = (page: CurrentPage, formValues: any = {}) => {
        const required = getRequiredKeys(formData.courierType);
        const requiredKeys = required[page];
        const declaredValue = formData.declaredValue;
        const checkDeclaredValueIsInt = (declaredValue && !Number.isNaN(declaredValue));
        const Value = checkDeclaredValueIsInt ? Number(declaredValue) : 0;
        const ewayBillObject = formData.ewayBill || {};
        if (formData.courierType === 'non-document' && Value > 50000 && showEwayBill && page === 'FIFTH') {
            const checkEwayType = (formData?.ewayBill?.type && (formData?.ewayBill?.type === 'ewaybill_number'));
            if (checkEwayType) {
                const err = ewayNumberKeys.some((key: string) => {
                    return !ewayBillObject[key];
                });
                if (err) {
                    return err;
                }
            }
            const ewayBillType = (formData?.ewayBill?.type && (formData?.ewayBill?.type === 'ewaybill_details'));
            if (ewayBillType) {
                const err = ewayBillKeys.some((key: string) => {
                    return !ewayBillObject[key];
                });
                if (err) {
                    return err;
                }
            }
        }
        const data = {
            ...formData,
            ...formValues,
        };
        const err = requiredKeys.some((key: string) => !data[key]);
        return err;
    };

    const submitDisabled = () => {
        const required = getRequiredKeys(formData.courierType);
        const formValues = form.getFieldsValue();
        const error = Object.keys(required).some((key) => {
            const err = checkError(key as CurrentPage, formValues);
            return err;
        });
        return error;
    };

    React.useEffect(() => {
        const allow = submitDisabled();
        setAllowCreate(allow);
    }, [formData]);

    const { currentPage } = state;

    const updatePieceDetails = (formDataPieces: any, formValuePieces: any) => {
        if (!formDataPieces) {
            return formValuePieces;
        }
        if (!formValuePieces) {
            return formDataPieces;
        }
        const pieces: Record<any, any> = {};
        Object.keys(formDataPieces).forEach((pieceKey: any) => {
            pieces[pieceKey] = {
                ...formDataPieces[pieceKey],
                ...formValuePieces[pieceKey],
            };
        });
        return pieces;
    };

    const updateFormData = () => {
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
            pieces: updatePieceDetails(formData.pieces, formValues.pieces),
        };
        setFormData(updatedData);
    };

    const customCheck = async () => {
        const required = [
            formFields.FOB_Value.key,
            formFields.TotalGSTPaidAmnt.key,
            formFields.Weight.key,
            formFields.DeclaredValue.key,
            formFields.CustomerReference.key,
            formFields.NumPieces.key,
            formFields.Cust_Seller_code.key,
            consignorFormFields.ConsignorName.key,
            consignorFormFields.ConsignorAddressLine1.key,
            consignorFormFields.ConsignorCity.key,
            consignorFormFields.ConsignorPincode.key,
            consignorFormFields.ConsignorCountry.key,
            consignorFormFields.ConsignorPhone.key,
            consignorFormFields.ConsignorEmail.key,
        ];
        if (isFTL) {
            required.push(formFields.VehicleCategory.key);
        }
        const data = {
            ...formData,
            ...form.getFieldsValue(),
        };
        const piecesKeys = Object.keys(mandatoryPieceDetails);
        const check: any[] = [];
        if (data.pieces && piecesKeys.length > 0) {
            Object.keys(data.pieces).forEach((key) => {
                piecesKeys.forEach((field) => {
                    if (mandatoryPieceDetails[field]) {
                        check.push(['pieces', key, field]);
                    }
                });
            });
        }
        if (data.courierType === 'non-document') {
            if (data.consignmentCategory === 'international') {
                check.push(...Object.keys(mandatoryFieldNonDocIntl).map((field) => {
                    return field;
                }));
            }
            if (data.shipmentPurpose === 'COMMERCIAL' && data.consignmentCategory === 'international') {
                check.push(...Object.keys(mandatoryFieldShipmentCommercial));
            }
        }
        if (data.destinationType === t('consignee_type_business')) {
            check.push('consigneeCompanyName');
        }

        required.forEach((key: string) => {
            check.push(key);
        });
        await form.validateFields(check);
    };

    const handleTabChange = async (page: CurrentPage) => {
        updateFormData();
        await customCheck();
        if (page === currentPage) {
            return;
        }
        setState({ currentPage: page });
    };

    const renderFilledCircle = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                className={activePage ? classes.filledCircle : classes.filledCircle}
            />
        );
    };

    const renderCircle = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                className={classes.tabIcon}
                style={{
                    border: activePage ? `2px solid ${uiTheme.primaryColor}`
                        : '2px solid #CCCCCC',
                    backgroundColor: activePage ? uiTheme.primaryColor : '#FFF',
                }}
            >
                {renderFilledCircle(page)}
            </div>
        );
    };

    const complete = () => {
        return (
            <CheckCircleFilled
                style={{
                    color: '#27B479',
                    fontSize: 24,
                }}
            />
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                style={{
                    color: '#EA2626',
                    fontSize: 24,
                }}
            />
        );
    };

    const completeIncompleteIcon = (page: CurrentPage) => {
        const err = checkError(page);
        if (err) {
            return inComplete();
        }
        return complete();
    };

    const renderTabIcon = (page: CurrentPage) => {
        if (currentPage === page) {
            return renderCircle(page);
        }
        switch (page) {
            case CurrentPage.FIRST: {
                if (state.visited.FIRST) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.SECOND: {
                if (state.visited.SECOND) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.THIRD: {
                if (state.visited.THIRD) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.FOUR: {
                if (state.visited.FOUR) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.FIFTH: {
                if (state.visited.FIFTH) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            default: return null;
        }
    };

    const renderTabName = (page: CurrentPage) => {
        return (
            <div
                className={classes.tabName}
            >
                {t(TitleTabNames[page])}
            </div>
        );
    };

    // const renderTabDescription = (page: CurrentPage) => {
    //   return (
    //   <div
    //     className={classes.tabDescription}
    //   >
    //     {TabDescription[page]}
    //   </div>
    //   );
    // };

    const renderTabDetails = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                style={{
                    color: activePage ? '#111111' : '#666666',
                    fontWeight: activePage ? 'bold' : 'normal',
                }}
            >
                {renderTabName(page)}
                {/* {renderTabDescription(page)} */}
            </div>
        );
    };

    const renderTab = (page: CurrentPage) => {
        if (!showPayment && page === CurrentPage.FIFTH) {
            return null;
        }
        const activePage = page === currentPage;
        return (
            <div
                key={page}
                className={classes.tabSelector}
                onClick={() => handleTabChange(page)}
                style={{
                    borderBottom: activePage
                        ? `4px solid ${uiTheme.secondryColor}` : 'none',
                }}
            >
                {renderTabIcon(page)}
                {renderTabDetails(page)}
            </div>
        );
    };

    const renderPageSelector = () => {
        return (
            <div
                className={classes.pageSelector}
            >
                {pagesToShow.map((page) => renderTab(page as CurrentPage))}
            </div>
        );
    };

    const commonProps = {
        config,
        formData,
        form,
        fieldsToShowInternational,
        isRTL,
    };

    const renderStepTwo = () => {
        if (currentPage !== CurrentPage.SECOND) {
            return null;
        }
        return (
            <StepTwo
                {...commonProps}
                setFormData={setFormData}
                page="Consignee"
                pageTitle={t(TitleTabNames.SECOND)}
                addressKey="dstAddress"
                phoneRegex={phoneRegex}
                countryWisePhoneRegex={countryWisePhoneRegex}
                allowDefaultSourceAddress={allowDefaultSourceAddress && customerPortalConfig?.default_addresses_limit}
                sameChecked={sameChecked}
                setSameChecked={setSameChecked}
            />
        );
    };

    const renderStepFour = () => {
        if (currentPage !== CurrentPage.FOUR) {
            return null;
        }
        return (
            <StepThree
                {...commonProps}
                nextPage={showPayment ? null : () => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    handleAction(ButtonAction.NEXT);
                }}
                isFTL={isFTL}
                showScheduleOnlyWhenHyperlocal={
                    partsToShowSingleConsignment && partsToShowSingleConsignment?.scheduleOnlyWhenHyperlocal
                }
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                getAPIBody={getAPIBody}
            />
        );
    };

    const renderStepFive = () => {
        if (currentPage !== CurrentPage.FIFTH) {
            return null;
        }
        return <StepFour {...commonProps} setFormData={setFormData} />;
    };

    const renderStepThree = () => {
        if (currentPage !== CurrentPage.THIRD) {
            return null;
        }
        const shouldUseSenderProfile = formData.shipmentPurpose === 'COMMERCIAL'
            ? profileMandateComm : profileMandateNonComm;
        return (
            <div style={formData.consignmentCategory === 'international'
                ? { maxHeight: 'calc(100vh - 210px)', overflow: 'scroll' }
                : {}}
            >
                { formData.consignmentCategory === 'international' && !shouldUseSenderProfile
                    ? (
                        <>
                            <ConsignorDetailsInteranational
                                {...commonProps}
                                setFormData={setFormData}
                                customerPortalConfig={customerPortalConfig}
                                setConsignorAddressType={setConsignorAddressType}
                                consignorAddressType={consignorAddressType}
                            />
                            <div className={classes.line} />
                        </>
                    )
                    : null}
                { formData.consignmentCategory === 'international'
                    && shouldUseSenderProfile
                    ? <SellerDetailsInternational {...commonProps} setFormData={setFormData} onClose={onClose} />
                    : null }
                <StepTwo
                    setFormData={setFormData}
                    {...commonProps}
                    page="Consignor"
                    pageTitle={t(TitleTabNames.THIRD)}
                    addressKey={formData.consignmentCategory !== 'international' || shouldUseSenderProfile
                        ? 'srcAddress' : 'consignorAddress'}
                    phoneRegex={phoneRegex}
                    countryWisePhoneRegex={countryWisePhoneRegex}
                    allowDefaultSourceAddress={allowDefaultSourceAddress}
                    setConsignorAddressType={setConsignorAddressType}
                    consignorAddressType={consignorAddressType}
                    sameChecked={sameChecked}
                    setSameChecked={setSameChecked}
                    shouldUseSenderProfile={shouldUseSenderProfile}
                />
            </div>
        );
    };

    const renderStepOne = () => {
        if (currentPage !== CurrentPage.FIRST) {
            return null;
        }
        return (
            <StepOne
                {...commonProps}
                isFTL={isFTL}
                setFormData={setFormData}
                source={source}
            />
        );
    };

    function getNextPage(action: ButtonAction) {
        let currentPageNumber = CurrentPageNumber[currentPage];
        if (action === ButtonAction.NEXT && currentPageNumber < pagesToShow.length) {
            currentPageNumber += 1;
        } else if (action === ButtonAction.PREV) {
            currentPageNumber -= 1;
        }
        switch (currentPageNumber) {
            case 1: return CurrentPage.FIRST;
            case 2: return CurrentPage.SECOND;
            case 3: return CurrentPage.THIRD;
            case 4: return CurrentPage.FOUR;
            case 5:
            default:
                if (showPayment) return CurrentPage.FIFTH;
                return CurrentPage.FOUR;
        }
    }

    async function handlePageChange(action: ButtonAction) {
        await customCheck();
        updateFormData();
        const nextPage = getNextPage(action);
        setState({ currentPage: nextPage });
    }

    const apiBodyPieceDetails = (piecesData: any[], isDocCn = false, isPluginUpdate = false) => {
        const piecesDetail: Record<any, any> = [];
        const allowAsSingle = consignmentConfig.allow_quantity_as_single_piece;
        if (piecesData) {
            if (formData.consignmentCategory === 'international') {
                // will be sending piece details empty if is CN type is document
                if (isDocCn) return undefined;
                Object.keys(piecesData).forEach((key: any, index: any) => {
                    const piece: any = piecesData[key];
                    piecesDetail.push({
                        description: piece.description,
                        declaredValue: (!isPluginUpdate) ? piece.declaredValue : undefined,
                        declared_value: isPluginUpdate ? piece.declaredValue : undefined,
                        reference_number: piece.reference_number,
                        item_details: isPluginUpdate ? Object.keys(piece.items || {}).map((itemKey) => {
                            return {
                                hsn_code: piece.items[itemKey].hsnCode,
                                gst_value: piece.items[itemKey].gstValue,
                                ate_number: piece.items[itemKey].ateNumber,
                                cess_value: piece.items[itemKey].cessValue,
                                duty_value: piece.items[itemKey].dutyValue,
                                item_value: piece.items[itemKey].itemValue,
                                item_rate: piece.items[itemKey].itemRate,
                                no_of_items: piece.items[itemKey].numberOfItems,
                                sku_number: piece.items[itemKey].skuNumber,
                                composition: piece.items[itemKey].composition,
                                product_url: piece.items[itemKey].productUrl,
                                product_type: piece.items[itemKey].productType,
                                product_width: piece.items[itemKey].productWidth,
                                product_name: piece.items[itemKey].productName,
                                rodtep_scheme: piece.items[itemKey].rodtepScheme,
                                gst_percentage: piece.items[itemKey].gstPercentage,
                                product_height: piece.items[itemKey].productHeight,
                                product_length: piece.items[itemKey].productLength,
                                origin_of_goods: piece.items[itemKey].originOfGoods,
                                item_description: piece.items[itemKey].itemDescription,
                                piece_number: piece.items[itemKey].pieceNumber,
                                product_weight: piece.items[itemKey].productDeadWeight,
                                item_size: piece.items[itemKey].itemSize,
                            };
                        }) : Object.keys(piece.items || {}).map((itemKey) => {
                            return {
                                ...piece.items[itemKey],
                                pieceNumber: index,
                            };
                        }),
                        length: Helper.converttoCentimeter(piece.length, piece.dimensionsUnit),
                        width: Helper.converttoCentimeter(piece.width, piece.dimensionsUnit),
                        height: Helper.converttoCentimeter(piece.height, piece.dimensionsUnit),
                        pieceNumber: (!isPluginUpdate) ? index : undefined,
                        piece_number: isPluginUpdate ? index : undefined,
                        weight: Helper.converttoKilogram(Number(piece.weight), piece.weightUnit),
                        volumetricWeight: (!isPluginUpdate) ? piece.volumetricWeight : undefined,
                        volumetric_weight: isPluginUpdate ? piece.volumetricWeight : undefined,
                        dimension_unit: 'cm',
                        weight_unit: 'kg',
                        numberOfItems: (!isPluginUpdate) ? Number(piece.numberOfPieces) : undefined,
                        number_of_items: isPluginUpdate ? Number(piece.numberOfPieces) : undefined,
                        // quantity: 1,
                    });
                });
            } else if (consignmentConfig?.allow_multiple_piece_dimensions) {
                Object.keys(piecesData).forEach((key: any) => {
                    const piece: any = piecesData[key];
                    if (allowAsSingle) {
                        for (let i = 0; i < piece.numberOfPieces; i += 1) {
                            piecesDetail.push({
                                length: Helper.converttoCentimeter(piece.length, piece.dimensionsUnit),
                                width: Helper.converttoCentimeter(piece.width, piece.dimensionsUnit),
                                height: Helper.converttoCentimeter(piece.height, piece.dimensionsUnit),
                                item_quantity: 1,
                                quantity: 1,
                                reference_number: piece.reference_number,
                                dimension_unit: 'cm',
                                weight_unit: 'kg',
                                item_unit: piece.unit,
                                weight: Helper.converttoKilogram(piece.weight, piece.weightUnit),
                                weightUnit: 'kg',
                            });
                        }
                    } else {
                        piecesDetail.push({
                            length: Helper.converttoCentimeter(piece.length, piece.dimensionsUnit),
                            width: Helper.converttoCentimeter(piece.width, piece.dimensionsUnit),
                            height: Helper.converttoCentimeter(piece.height, piece.dimensionsUnit),
                            item_quantity: piece.numberOfPieces || 1,
                            quantity: piece.numberOfPieces || 1,
                            item_unit: piece.unit,
                            weight: Helper.converttoKilogram(piece.weight, piece.weightUnit),
                            weightUnit: 'kg',
                            dimension_unit: 'cm',
                            reference_number: piece.reference_number,
                            weight_unit: 'kg',
                            codAmount: piece.codAmount,
                        });
                    }
                });
            } else {
                return undefined;
            }
            return piecesDetail;
        }
        return undefined;
    };

    async function PluginUpdate(src: string) {
        updateFormData();
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
            pieces: updatePieceDetails(formData.pieces, formValues.pieces),
        };
        setCreating(true);
        const pickupTime = updatedData?.pickupTime?.split('-');
        const shipment_purpose = updatedData.courierType === 'non-document'
            ? updatedData.shipmentPurpose : updatedData.shipmentPurposeDoc;
        const isCOD = updatedData.isCOD === 'yes';
        const apiBody = {
            softdata_body: {
                is_booked_using_v2: true,
                app_name: updatedData.appName,
                sales_order_number: updatedData.salesOrderNumber,
                shopify_order_id: updatedData.shopifyOrderId,
                shopify_fulfillment_id: updatedData.shopifyFulfillmentId,
                all_pieces_with_same_dimensions: false,
                cod_amount: isCOD ? (updatedData.codAmount || undefined) : undefined,
                cod_collection_mode: isCOD ? (updatedData.codCollectionMode || null) : null,
                cod_favor_of: isCOD ? updatedData.codFavorOf : undefined,
                // sending last value, to get final selected commodity Id in Cascader
                commodity_id: Helper.isValidArray(updatedData.commodityId) ? [last(updatedData.commodityId)] : undefined,
                currency: updatedData.currency,
                consignment_category: updatedData.consignmentCategory,
                international_detail_shipment_purpose: updatedData.shipmentPurpose,
                consignment_type: updatedData.consignmentType,
                courier_type: updatedData.courierType,
                ret_address: updatedData?.retAddress,
                alternate_del_address1: updatedData?.alternateDelAddress1,
                alternate_del_address2: updatedData?.alternateDelAddress2,
                redirection_address: updatedData.redirectionAddress,

                customer_reference_number: updatedData.customerReferenceNumber,
                cop_amount: updatedData.cashonpickup,
                is_retail_transaction: updatedData.retailTransactionType === 'retailTransactionTrue' || false,
                type_of_delivery: updatedData.typeOfDelivery,
                description: updatedData.description,
                length: Helper.converttoCentimeter(updatedData.length, updatedData.unit),
                width: Helper.converttoCentimeter(updatedData.width, updatedData.unit),
                height: Helper.converttoCentimeter(updatedData.height, updatedData.unit),
                destination_name: updatedData.dstAddress.name,
                destination_phone: updatedData.dstAddress.phone,
                destination_address_line_1: updatedData.dstAddress.addressLine1,
                destination_address_line_2: updatedData.dstAddress.addressLine2,
                destination_address_line_3: updatedData.dstAddress.addressLine3,
                destination_address_line_4: updatedData.dstAddress.addressLine4,
                destination_state: updatedData.dstAddress.stateName,
                destination_city: updatedData.dstAddress.cityName,
                destination_pincode: updatedData.dstAddress.pincode,
                destination_email: updatedData.dstAddress.email,
                destination_country: updatedData.dstAddress.countryName,
                alternate_phone: updatedData.dstAddress.alternatePhone,
                sender_alternate_phone: updatedData.srcAddress.alternatePhone,

                load_type: updatedData.courierType,
                num_pieces: updatedData.numberOfPieces,
                no_of_pieces: updatedData.numberOfPieces,
                service_type: updatedData.serviceType,
                is_cod: updatedData.isCOD === 'yes',
                eway_bill: updatedData.consignmentCategory === 'international'
                    ? updatedData.ewayBillNumber : updatedData.ewayBill,
                kirana_hub_code: updatedData.kiranaHubCode,
                is_fragile: false,
                is_international: updatedData.consignmentCategory === 'international',
                is_risk_surcharge_applicable: updatedData.isRiskSurchargeApplicable,
                number_of_pieces: Number(updatedData.numberOfPieces),
                pieces: apiBodyPieceDetails(updatedData.pieces, updatedData.courierType === 'document', true),
                reference_number: updatedData.referenceNumber,
                // servicetype: updatedData.serviceType,
                sender_name: updatedData.srcAddress.name,
                sender_phone: updatedData.srcAddress.phone,
                sender_address_line_1: updatedData.srcAddress.addressLine1,
                sender_address_line_2: updatedData.srcAddress.addressLine2,
                sender_address_line_3: updatedData.srcAddress.addressLine3,
                sender_address_line_4: updatedData.srcAddress.addressLine4,
                sender_state: updatedData.srcAddress.stateName,
                sender_city: updatedData.srcAddress.cityName,
                sender_pincode: updatedData.srcAddress.pincode,
                sender_email: updatedData.srcAddress.email,
                sender_country: updatedData.srcAddress.countryName,
                weight: Helper.converttoKilogram(Number(updatedData.weight), updatedData.weightUnit),
                // declared_price: updatedData.declaredValue,
                declared_value: updatedData.declaredValue,
                child_client: {
                    code: updatedData?.childClient?.split('+')[0],
                    id: updatedData?.childClient?.split('+')[1],
                },
                cust_seller_code: updatedData.custSellerCode,
                shipment_purpose,
                delivery_instructions: updatedData.deliveryInstructions,
                eori_number: updatedData.eoriNumber,
                iori_number: updatedData.ioriNumber,
                freight_cost: updatedData.freightCost,
                freight_cost_currency: updatedData.freightCostCurrency,
                against_bond_lut: updatedData.againstBondLut,
                ecom_shipment: updatedData.ecomShipment ? updatedData.ecomShipment === 'true' : undefined,
                meis: updatedData.meis ? updatedData.meis === 'true' : undefined,
                is_battery: updatedData?.isBattery ? updatedData?.isBattery === 'true' : undefined,
                export_invoice_date: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
                fob_value_currency: updatedData.fobValueCurrency,
                fob_value: updatedData.fobValue,
                insurance_value: updatedData.insuranceValue,
                insurance_value_currency: updatedData.insuranceValueCurrency,
                total_GST_paid_currency: updatedData.totalGSTPaidCurrency,
                total_GST_paid_amount: updatedData.totalGSTPaidAmount,
                consignor_company_name: updatedData.consignorCompanyName,
                consignor_tax_id: updatedData.consignorTaxId,
                consignor_IEC_number: updatedData.consignorIECNumber,
                consignor_GST_IN_number: updatedData.consignorGSTINNumber,
                consignor_pan_id: updatedData.consignorPanId,
                consignor_kyc_doc_type: updatedData.consignorKycDocType,
                consignor_kyc_doc_number: updatedData.consignorKycDocNumber,
                consignor_kyc_front_image: updatedData.consignorKycFrontImage,
                consignor_kyc_back_image: updatedData.consignorKycBackImage,
                sender_bank_account_number: updatedData.senderBankAccountNumber,
                sender_bank_AD_code: updatedData.senderBankADCode,
                sender_bank_name: updatedData.senderBankName,
                sender_bank_IFSC_code: updatedData.senderBankIFSCCode,
                nefi_flag: updatedData.nefiFlag ? updatedData.nefiFlag === 'true' : undefined,
                cpc_code: updatedData.cpcCode,
                cess_value: updatedData.cessValue,
                eway_bill_number: updatedData.ewayBillNumber,
                invoice_type: updatedData.invoiceType,
                invoice_date: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
                invoice_number: updatedData.invoiceNumber,
                bill_address: updatedData.billToDetails,
                consignee_company_name: updatedData.consigneeCompanyName,
                inco_terms: updatedData.incoTerms,
                delivery_terms: updatedData.deliveryTerms,
                origin_type: updatedData.originType,
                destination_type: updatedData.destinationType,
                // sending the dimenstion & weight data after converting api body
                dimension_unit: 'cm',
                weight_unit: 'kg',
                receiver_VAT_number: updatedData.receiverVATNumber,
                pickup_date: moment(updatedData.pickupSchedule).format('YYYY-MM-DD'),
                pickup_time_slot_start: pickupTime ? pickupTime[0]?.trim() : undefined,
                pickup_time_slot_end: pickupTime ? pickupTime[1]?.trim() : undefined,
                declared_currency: updatedData.declaredCurrency,
                commodity_name: Helper.isValidArray(updatedData.commodityId) ? updatedData?.commodityId[0] : null,
                // commodity_id: updatedData?.commodityId[1],
                ewb_number: updatedData.ewbNumber,
                ewb_date: moment(updatedData.ewbDate).format('YYYY-MM-DD'),
                risk_surcharge_type: config?.config?.customer_portal_config?.show_risk_surcharge_type
                    ? updatedData.riskSurchargeType : null,
                consignment_flow_type: 'Standard',
                unlisted_commodity_name: updatedData.unlistedCommodityName,
            },
            id: editData?.id,
        };
        const response = await UpdatePluginOrder(apiBody);
        if (response.isSuccess) {
            loadPluginOrders();
            if (src !== 'submit') {
                setSuccess({
                    ...(response?.data || []),
                    showInvoicePrint: config?.Customer?.show_invoice_options
                        && updatedData.consignmentCategory === 'international'
                        && updatedData.courierType === 'non-document',
                });
            }
        } else {
            loadPluginOrders();
            message.error(response.errorMessage);
        }
        setCreating(false);
    }

    async function getAPIBody() {
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
            pieces: updatePieceDetails(formData.pieces, formValues.pieces),
        };
        const pickupTime = updatedData?.pickupTime?.split('-');
        const shipmentPurpose = updatedData.courierType === 'non-document'
            ? updatedData.shipmentPurpose : updatedData.shipmentPurposeDoc;
        const isCOD = updatedData.isCOD === 'yes';
        const employee = getEmployeeStorage();
        const isSuperUser = isSuperUserEmployee();
        const consignorAddress = formData.consignmentCategory === 'international' ? {
            name: updatedData.consignorName,
            addressLine1: updatedData.consignorAddressLine1,
            addressLine2: updatedData.consignorAddressLine2,
            cityName: updatedData.consignorCity,
            stateName: updatedData.consignorState,
            countryName: updatedData.consignorCountry,
            pincode: updatedData.consignorPincode,
            phone: updatedData.consignorPhone,
            email: updatedData.consignorEmail,
        } : undefined;
        const shouldUseSenderProfile = updatedData.shipmentPurpose === 'COMMERCIAL'
            ? profileMandateComm : profileMandateNonComm;
        const apiBody = {
            consignorAddress,
            isBookedUsingV2: true,
            shopifyOrderId: (source || '') === 'plugin' ? updatedData?.shopifyOrderId : undefined,
            shopifyFulfillmentId: (source || '') === 'plugin' ? updatedData?.shopifyFulfillmentId : undefined,
            creationSource: (source || '') === 'plugin' ? 'SHOPIFY_CUSTOMER_PORTAL_SINGLE' : undefined,
            salesOrderNumber: (source || '') === 'plugin' ? updatedData?.salesOrderNumber : undefined,
            allPiecesWithSameDimensions: false,
            codAmount: isCOD ? (updatedData.codAmount || undefined) : undefined,
            codCollectionMode: isCOD ? (updatedData.codCollectionMode || null) : null,
            codFavorOf: isCOD ? updatedData.codFavorOf : undefined,
            // sending last value, to get final selected commodity Id in Cascader
            commodityId: Helper.isValidArray(updatedData.commodityId) ? [last(updatedData.commodityId)] : undefined,
            currency: updatedData.currency,
            consignmentCategory: updatedData.consignmentCategory,
            consignmentType: updatedData.consignmentType,
            courierType: updatedData.courierType,
            customerReferenceNumber: updatedData.customerReferenceNumber,
            copAmount: updatedData.cashonpickup,
            isRetailTransaction: updatedData.retailTransactionType === 'retailTransactionTrue' || false,
            typeOfDelivery: updatedData.typeOfDelivery,
            description: updatedData.description,
            dimensions: {
                length: Helper.converttoCentimeter(updatedData.length, updatedData.unit),
                width: Helper.converttoCentimeter(updatedData.width, updatedData.unit),
                height: Helper.converttoCentimeter(updatedData.height, updatedData.unit),
            },
            dstAddress: updatedData.dstAddress,
            vehicleCategory: updatedData.vehicleCategory,
            ewayBill: formData.consignmentCategory === 'international' ? updatedData.ewayBillNumber : updatedData.ewayBill,
            kiranaHubCode: updatedData.kiranaHubCode,
            isFragile: false,
            isInternational: formData.consignmentCategory === 'international',
            isRiskSurchargeApplicable: updatedData.isRiskSurchargeApplicable,
            numberOfPieces: Number(updatedData.numberOfPieces) || 1,
            piecesDetail: apiBodyPieceDetails(updatedData.pieces, updatedData.courierType === 'document', false),
            referenceNumber: updatedData.referenceNumber,
            retAddress: updatedData.retAddress,
            serviceType: updatedData.serviceType,
            srcAddress: formData.consignmentCategory === 'international'
                ? (updatedData.srcAddress || updatedData.consignorAddress)
                : updatedData.srcAddress,
            weight: Helper.converttoKilogram(Number(updatedData.weight), updatedData.weightUnit),
            declaredPrice: updatedData.declaredValue,
            alternateDelAddress1: updatedData.alternateDelAddress1,
            alternateDelAddress2: updatedData.alternateDelAddress2,
            redirectionAddress: updatedData.redirectionAddress,
            childClient: {
                code: updatedData?.childClient?.split('+')[0],
                id: updatedData?.childClient?.split('+')[1],
            },
            custSellerCode: updatedData.custSellerCode,
            shipmentPurpose,
            deliveryInstructions: updatedData.deliveryInstructions,
            eoriNumber: updatedData.eoriNumber,
            ioriNumber: updatedData.ioriNumber,
            freightCost: updatedData.freightCost,
            freightCostCurrency: updatedData.freightCostCurrency,
            againstBondLut: updatedData.againstBondLut,
            ecomShipment: updatedData.ecomShipment ? updatedData.ecomShipment === 'true' : undefined,
            meis: updatedData.meis ? updatedData.meis === 'true' : undefined,
            isBattery: updatedData.isBattery ? updatedData.isBattery === 'true' : undefined,
            exportInvoiceDate: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
            fobValueCurrency: updatedData.fobValueCurrency,
            fobValue: updatedData.fobValue,
            insuranceValue: updatedData.insuranceValue,
            insuranceValueCurrency: updatedData.insuranceValueCurrency,
            totalGSTPaidCurrency: updatedData.totalGSTPaidCurrency,
            totalGSTPaidAmount: updatedData.totalGSTPaidAmount,
            consignorCompanyName: updatedData.consignorCompanyName,
            consignorTaxId: updatedData.consignorTaxId,
            consignorIECNumber: updatedData.consignorIECNumber,
            consignorGSTINNumber: updatedData.consignorGSTINNumber,
            consignorPanId: updatedData.consignorPanId,
            consignorKycDocType: updatedData.consignorKycDocType,
            consignorKycDocNumber: updatedData.consignorKycDocNumber,
            consignorKycFrontImage: updatedData.consignorKycFrontImage,
            consignorKycBackImage: updatedData.consignorKycBackImage,
            senderBankAccountNumber: updatedData.senderBankAccountNumber,
            senderBankADCode: updatedData.senderBankADCode,
            senderBankName: updatedData.senderBankName,
            senderBankIFSCCode: updatedData.senderBankIFSCCode,
            nefiFlag: updatedData.nefiFlag ? updatedData.nefiFlag === 'true' : undefined,
            cpcCode: updatedData.cpcCode,
            cessValue: updatedData.cessValue,
            ewayBillNumber: updatedData.ewayBillNumber,
            invoiceType: updatedData.invoiceType,
            invoiceDate: updatedData?.invoiceDate?.format('YYYY-MM-DD'),
            invoiceNumber: updatedData.invoiceNumber,
            billAddress: updatedData.billToDetails,
            consigneeCompanyName: updatedData.consigneeCompanyName,
            incoTerms: updatedData.incoTerms,
            deliveryTerms: updatedData.deliveryTerms,
            originType: updatedData.originType,
            destinationType: updatedData.destinationType,
            // sending the dimenstion & weight data after converting api body
            dimensionUnit: 'cm',
            weightUnit: 'kg',
            receiverVATNumber: updatedData.receiverVATNumber,
            pickupDate: moment(updatedData.pickupSchedule).format('YYYY-MM-DD'),
            pickupTimeSlotStart: pickupTime ? pickupTime[0]?.trim() : undefined,
            pickupTimeSlotEnd: pickupTime ? pickupTime[1]?.trim() : undefined,
            declaredCurrency: updatedData.declaredCurrency,
            unlistedCommodityName: updatedData?.unlistedCommodityName,
            ewbNumber: updatedData.ewbNumber,
            ewbDate: moment(updatedData.ewbDate).format('YYYY-MM-DD'),
            riskSurchargeType: config?.config?.customer_portal_config?.show_risk_surcharge_type
                ? updatedData.riskSurchargeType : null,
            consignmentFlowType: 'Standard',
            srfNumber: updatedData.srfNumber,
            source: (editData && source !== 'plugin') ? 'CUSTOMER_PORTAL_SINGLE_UPDATE' : undefined,
            createdBy: isSuperUser ? `${employee.employeeCode} (Employee)`
                : `${updatedData?.childClient?.split('+')[0]} (Customer)`,
            sellerId: shouldUseSenderProfile ? updatedData?.sellerDetails?.seller_id : undefined,
            sellerCode: shouldUseSenderProfile ? updatedData?.sellerDetails?.seller_code : undefined,
            ...(isLTLCustomer && {
                payBasis: updatedData?.payBasis,
                pickupHubCode: updatedData?.pickupHubCode,
            }),
        };
        return {
            updatedData,
            apiBody,
        };
    }

    async function checkForErrors() {
        updateFormData();
        await customCheck();
        const {
            apiBody, updatedData,
        } = await getAPIBody();
        setCreating(true);
        const response = await createConsignment(apiBody);
        if (response.isSuccess) {
            loadconsignments();
            setSuccess({
                ...(response?.data || []),
                showInvoicePrint: config?.Customer?.show_invoice_options
                    && updatedData.consignmentCategory === 'international'
                    && updatedData.courierType === 'non-document',
            });
        } else {
            message.error(response.errorMessage);
            if (source === 'plugin') {
                await PluginUpdate('submit');
            }
        }
        setCreating(false);
    }

    const handleSubmitConsignment = () => {
        if (formData.consignmentCategory === consignmentCategoryTypes.INTERNATIONAL) {
            setConsentForm(true);
        } else {
            checkForErrors();
        }
    };

    function handleAction(action: ButtonAction) {
        switch (action) {
            case ButtonAction.PREV:
            case ButtonAction.NEXT: return handlePageChange(action);
            case ButtonAction.SUBMIT: return handleSubmitConsignment();
            case ButtonAction.SAVE_DRAFT: return PluginUpdate('Update');
            default: return null;
        }
    }

    const renderButton = (name: string, action: ButtonAction) => {
        const btnClass = classes.nextPreButton;

        return (
            <Button
                type={(action === ButtonAction.SUBMIT || action === ButtonAction.SAVE_DRAFT) ? 'primary' : 'link'}
                onClick={() => handleAction(action)}
                style={{ marginRight: 16 }}
                loading={creating}
                disabled={action === ButtonAction.SUBMIT && allowCreate}
                className={btnClass}
            >
                {action === ButtonAction.PREV && <LeftOutlined />}
                {name}
                {action === ButtonAction.NEXT && <RightOutlined />}
            </Button>
        );
    };

    const renderLeftButton = () => {
        switch (currentPage) {
            case CurrentPage.SECOND:
            case CurrentPage.THIRD:
            case CurrentPage.FOUR:
            case CurrentPage.FIFTH: return renderButton(t(ButtonNames.PREV), ButtonAction.PREV);
            case CurrentPage.FIRST:
            default: return null;
        }
    };

    const renderRightButton = () => {
        switch (currentPage) {
            case CurrentPage.FIRST:
            case CurrentPage.SECOND:
            case CurrentPage.THIRD:
            case CurrentPage.FOUR: return renderButton(t(ButtonNames.NEXT), ButtonAction.NEXT);
            case CurrentPage.FIFTH:
            default: return null;
        }
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <div>
                    {renderLeftButton()}
                    {renderRightButton()}
                </div>
                <div>
                    {renderButton(t(ButtonNames.SUBMIT), ButtonAction.SUBMIT)}
                    {source === 'plugin' ? renderButton(t(ButtonNames.SAVE), ButtonAction.SAVE_DRAFT) : null}
                </div>
            </div>
        );
    };

    /* eslint-disable max-len */
    const renderConsentForm = () => {
        return (
            <Modal
                width="60%"
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Consent Form')}
                    </div>
                )}
                visible={consentForm}
                onCancel={() => setConsentForm(false)}
                footer={[
                    <Button
                        key="agree"
                        type="primary"
                        disabled={!agreeToTerms}
                        onClick={() => {
                            checkForErrors();
                            setConsentForm(false);
                        }}
                    >
                        {t('Agree')}
                    </Button>,
                ]}
            >
                <div>
                    <h3 style={{ fontWeight: 600 }}>Note</h3>
                    <ol>
                        <li>Commercial shipments(CSB-5) allowed of value less than INR 10,00,000 only</li>
                        <li>No export incentive except MEIS on applicable export commodities</li>
                        <li>Weight per piece should not exceed 30 KG,Whereas Total Shipment weight can exceed on Single AWB.</li>
                        <li>DTDC shall copy first six digit of Export HS code into Commodity Code (Import). In case you have a different code(8 digits and For US & CA, 10 digits) available, please mention it in the respective field.</li>
                    </ol>
                    <h3 style={{ fontWeight: 600 }}>Terms and Conditions</h3>
                    <ol>
                        <li>
                            DTDC Express Ltd would be relying on the information provided by you for presenting the final shipping bill to Customs for release of courier shipments in compliance with the Customs Act and Rules. DTDC and its affiliates, subsidiaries, employees, officers, directors and agents, expressly disclaim any liability or responsibility for any loss that you may incur in the event of any inaccurate, false or incomplete information. You hereby certify that true, correct, and complete information is provided through Web-Services integration or any of the DTDCs shipping tools interchanged through the system and such information completely matches with the physical invoice submitted by you. You agree and acknowledge that, you shall be solely responsible for any kind of misdeclaration and shall be liable for all consequences due to non-compliance or violation of law.
                        </li>
                    </ol>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Checkbox
                        className={classes.checkbox}
                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                    >
                        I have read, understood and agree to the terms and conditions
                    </Checkbox>
                </div>
            </Modal>
        );
    };
    /* eslint-enable max-len */

    const renderCloseConfirmModal = () => {
        return (
            <Modal
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Confirm Action')}
                    </div>
                )}
                visible={closeConfirm}
                onCancel={() => setCloseConfirm(false)}
                footer={[
                    <Button key="back" type="text" onClick={() => setCloseConfirm(false)}>
                        {t('No, Continue')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={onClose}>
                        {t('Yes, Cancel')}
                    </Button>,
                ]}
            >
                { i18n.exists('exit_cn_create_form')
                    ? t('exit_cn_create_form')
                    : ('Exiting will result in unsaved changes to your consignment creation.'
                    + ' Do you want to cancel adding consignment now?')}
            </Modal>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross onClick={() => setCloseConfirm(true)} alt="close" className={classes.closeIcon} />
                    <span>{editData ? 'Edit Consignment' : t('add_consignment')}</span>
                </div>
                {/* {renderButton(t(ButtonNames.SUBMIT), ButtonAction.SUBMIT)} */}
            </div>
        );
    };


    return (
        <Drawer
            visible
            width="80%"
            title={renderHeader()}
            footer={renderFooter()}
            onClose={() => setCloseConfirm(true)}
            className={classes.main}
            closable={false}
            footerStyle={{
                padding: 0,
                margin: 0,
            }}
        >
            <Form form={form}>
                {renderPageSelector()}
                {renderStepOne()}
                {renderStepTwo()}
                {renderStepThree()}
                {renderStepFour()}
                {renderStepFive()}
                {/* {renderFooter()} */}
                {renderCloseConfirmModal()}
                {renderConsentForm()}
            </Form>
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(singleConsignmentStyles)(GenericHoc(hocConfig)(SingleConsignment));
